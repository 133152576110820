import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Fade,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Brightness1OutlinedIcon from "@material-ui/icons/Brightness1Outlined";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import IconButtonDefault from "../../../../common/buttons/IconButtonDefault";
import firebase from "../../../../../utils/firebase";
import { useDispatch, useSelector } from "react-redux";
import { logError, logInfo } from "../../../../../services/logging";
import placeholderLight from "../../../../../assets/images/placeholder_light.png";
import placeholderDark from "../../../../../assets/images/placeholder_dark.png";

import {
  getLocalStore,
  removeLocalStore,
  setLocalStore,
} from "../../../../../utils/storage/localStorage";
import QRCode from "react-qr-code";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
import EmailActivationError from "../EmailActivationError";
import { useRef } from "react";
import { Icon } from "@iconify/react";
import {
  fetchLoyaltyListSuccess,
  fetchRewardDetailsListSuccess,
} from "../../CatMenu/redux/actions";

const colors = [
  "#4CAF50",
  "#FF5722",
  "#03A9F4",
  "#E91E63",
  "#FFEB3B",
  "#673AB7",
  "#009688",
  "#FFC107",
  "#9C27B0",
  "#CDDC39",
  "#FF9800",
  "#8BC34A",
  "#2196F3",
  "#FF5722",
  "#607D8B",
  "#00BCD4",
  "#FFEB3B",
  "#795548",
  "#3F51B5",
  "#F44336",
  "#4CAF50",
  "#FF5722",
  "#03A9F4",
  "#E91E63",
  "#FFEB3B",
  "#673AB7",
];

const useStyles = makeStyles((theme) => ({
  roots: (props) => ({
    paddingTop: "14px",
    backgroundColor: props.popupBackgroundColor,
    "& .MuiTabs-root": {
      maxWidth: "calc(100vw - 320px)",
    },
    "& .MuiTabs-flexContainer": {
      paddingBottom: "2px",
      paddingLeft: "20px",
    },
  }),
  rewardCardBody: {
    backgroundColor: theme.palette.background.paper,
  },
  logOutButton: (props) => ({
    color: props.popupTextColor,
    width: "100%",
    border: `1px solid ${props.boarderColor}`,
    backgroundColor: props.buttonBackgroundColor,
    marginRight: 20,
    borderRadius: "10px",
    cursor: "pointer",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    height: "48px",
  }),
  tabRoot: (props) => ({
    "&.MuiTab-root": {
      color: props.popupTextColor,
      border: `1px solid ${props.boarderColor}`,
      backgroundColor: props.buttonBackgroundColor,
      marginRight: 20,
      borderRadius: "10px",
      fontWeight: "bold",
    },
    "&.Mui-selected": {
      color: theme.palette.secondary.main,
      border: `2px solid ${props.selectedBoarderColor}`,
      fontWeight: "bold",
      marginRight: 20,
      borderRadius: "10px",
    },
  }),
  arrowIcon: {
    color: theme.palette.text.titleTextColor,
    width: 30,
  },
  arrow: {
    width: 22,
  },
  divider: (props) => ({
    border: "none", // Remove default border
    height: "1px", // Thickness of the divider
    background: `repeating-linear-gradient(
    to right,
    ${props.dividerColor},
    ${props.dividerColor} 10px, /* Dash width */
    transparent 2px,
    transparent 16px /* Gap between dashes */
  )`,
    width: "100%",
  }),
  avatar: {
    backgroundColor: "#4CAF50", // Green background color
    color: theme.palette.common.white, // White text color
    width: theme.spacing(9), // Adjust the size if needed
    height: theme.spacing(9),
    fontSize: "24px", // Size of the letter inside
  },
  tabsIndicator: {
    display: "none",
  },

  buttonStyle: {
    // border: `1px solid ${theme.palette.text.accentTextColor}`,
    display: "block",
    textTransform: "none",
    borderRadius: "10px",
    marginBottom: "8px",
  },

  addressStyle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primaryTextColor,
    minHeight: "72px",
    // margin: "20px 4px 4px 4px",
    borderRadius: "10px",
    padding: "4px 12px 8px 12px",
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const UserDetailsModal = () => {
  const [activeIdx, setActiveIdx] = useState(0);

  const { params } = useRouteMatch();

  const customerId = getLocalStore("customerId");
  const shortId = getLocalStore("shortId");
  const photoURL = getLocalStore("photoURL");
  const auth = useSelector((state) => state.auth);

  const isActiveLoyalty = useSelector(
    (state) => state.loyalty[`isActiveLoyalty_${params.locationId}`],
  );
  const rewardDetailsList = useSelector(
    (state) => state.rewardDetails[`rewardDetailsList_${params.locationId}`],
  );
  const customerDetailsList = useSelector(
    (state) =>
      state.customerDetails[`customerDetailsList_${params.locationId}`],
  );

  const [isActivatedLoyalty, setIsActivatedLoyalty] = useState(false);
  const [rewardNode, setRewardNode] = useState([]);

  const dispatch = useDispatch();
  console.log("sddddddddddddddfdgfdgfdg", customerDetailsList);
  const theme = useTheme();

  useEffect(() => {
    setActiveIdx(0);
  }, []);

  useEffect(() => {
    if (isActiveLoyalty?.isActive?.toString() === "true") {
      setIsActivatedLoyalty(true);
    } else {
      setIsActivatedLoyalty(false);
    }
  }, [isActiveLoyalty]);

  useEffect(() => {
    if (!_.isEmpty(rewardDetailsList)) {
      setRewardNode(rewardDetailsList);
    } else {
      setRewardNode([]);
    }
  }, [rewardDetailsList]);

  const handleChange = (event, newValue) => {
    setActiveIdx(newValue);
  };

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        logInfo({
          message: `User ${getLocalStore("userId")} logged out success`,
        });

        removeLocalStore("idToken");
        removeLocalStore("username");
        removeLocalStore("userId");
        removeLocalStore("emailVerified");
        dispatch(
          fetchRewardDetailsListSuccess({
            locationId: params.locationId,
            points: [],
          }),
        );

        dispatch(
          fetchLoyaltyListSuccess({
            locationId: params.locationId,
            loyalty: { isActive: false },
          }),
        );
        // Define window.EATPRESTO if it doesn't exist
        if (!window.EATPRESTO) {
          window.EATPRESTO = {
            postMessage: (message) => {
              // Here you could also handle any required logging or fallback behavior.
            },
          };
        }

        // Send the logout message
        window.EATPRESTO.postMessage("logout_success");
      })
      .catch((error) => {
        logError({
          message: `Error logging out user ${getLocalStore("userId")}`,
          error,
        });
      });
  };

  const handleCircle = (no_of_spaces, state, stamped_spaces) => {
    const circles = [];

    for (let i = 0; i < no_of_spaces; i++) {
      circles.push(
        <Typography
          key={i}
          variant="caption"
          style={{
            display: "inline-flex",
            paddingRight: "4px",
            color:
              state === "pending"
                ? theme.palette.text.titleTextColor
                : state === "ready"
                ? "green"
                : "red",
          }}
        >
          {i < stamped_spaces ? (
            <Brightness1Icon style={{ fontSize: "22px" }} />
          ) : (
            <Brightness1OutlinedIcon style={{ fontSize: "22px" }} />
          )}
        </Typography>,
      );
    }

    return circles;
  };

  const matchesSmallScreen = useMediaQuery("(max-width: 600px)");
  // Get the first letter of the email and find its index in the alphabet
  const firstChar = auth.email ? auth.email.charAt(0).toUpperCase() : "A";
  const charIndex = firstChar.charCodeAt(0) - 65; // A is 65 in ASCII, Z is 90

  // Utility to calculate luminance and determine theme
  const isDarkTheme = (primaryColor) => {
    const rgb = parseInt(primaryColor.substring(1), 16); // Remove # and parse as hex
    const r = (rgb >> 16) & 0xff; // Red
    const g = (rgb >> 8) & 0xff; // Green
    const b = (rgb >> 0) & 0xff; // Blue
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b; // Relative luminance formula
    return luminance < 128; // Dark if luminance is less than 128
  };

  const isDark = isDarkTheme(theme.palette.primary.main);
  const popupBackgroundColor = isDark ? "#212121" : "#FFFFFF";
  const dividerColor = isDark ? "#212121" : "#808080";
  const popupTextColor = isDark ? "#FFFFFF" : "#808080";
  const buttonBackgroundColor = isDark ? "#464646" : "#FFFFFF";
  const boarderColor = isDark ? "#464646" : "#808080";
  const selectedBoarderColor = isDark
    ? "#464646"
    : theme.palette.secondary.main;

  const classes = useStyles({
    popupBackgroundColor,
    popupTextColor,
    buttonBackgroundColor,
    boarderColor,
    selectedBoarderColor,
    dividerColor,
  });

  let emailVerified = getLocalStore("emailVerified");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  useEffect(() => {
    setIsEmailVerified(emailVerified);
  }, [emailVerified]);

  const blurStyle = {
    filter: "blur(3px)", // Apply the blur effect
    pointerEvents: "none", // Prevent interaction while blurred
  };
  const [isLoad, setIsLoad] = useState(false);

  const checkEmailVerification = () => {
    const user = firebase.auth().currentUser;
    if (user) {
      user.reload().then(async () => {
        if (user.emailVerified) {
          const idToken = await user.getIdToken(true);
          setLocalStore("emailVerified", true);
          setIsEmailVerified(true);
        }
      });
    }
  };

  const countRef = useRef(0); // Use a ref to keep track of the count

  useEffect(() => {
    if (isLoad) {
      // Set an interval to check the email verification status every 3 seconds
      const intervalId = setInterval(() => {
        countRef.current += 1; // Increment the count using the ref
        checkEmailVerification();

        // Stop the interval after 10 attempts
        if (countRef.current >= 10) {
          clearInterval(intervalId);
        }
      }, 3000);

      // Clean up the interval when the component is unmounted or modal closes
      return () => clearInterval(intervalId);
    }
  }, [isLoad]);
  const [userImage, setUserImage] = useState("");
  useEffect(() => {
    setUserImage(photoURL || "");
  }, [photoURL]);

  const [isOpenAddress, setIsOpenAddress] = useState(false);
  const addressFormatted = getLocalStore("addressFormatted");

  const handleAddresses = () => {
    setIsOpenAddress(true);
  };

  /**
   * This function, handleAddressFirstRaw, takes a data object representing a delivery address and
   * constructs the first line of the address.
   */
  const handleAddressFirstRaw = (data) => {
    const { addressLine1, doorNo } = data;
    const result = [];

    // Check if doorNo exists and is not empty, then add it to the result array
    if (doorNo) {
      result.push(doorNo);
    }

    // Check if addressLine1 exists and is not empty, then add it to the result array
    if (addressLine1) {
      result.push(addressLine1);
    }

    // Join the address elements in the result array with a comma and space separator
    const output = result.join(", ");
    return output;
  };

  /**
   * This function, handleAddressSecondRaw, takes a data object representing a delivery address and
   * constructs the second line of the address.
   */
  const handleAddressSecondRaw = (data) => {
    const { addressLine2, city } = data;
    const result = [];

    // Check if addressLine2 exists and is not empty, then add it to the result array
    if (addressLine2) {
      result.push(addressLine2);
    }

    // Check if city exists and is not empty, then add it to the result array
    if (city) {
      result.push(city);
    }

    // Join the address elements in the result array with a comma and space separator
    const output = result.join(", ");
    return output;
  };

  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  console.log("sad876767asdajhhhfhgff", photoURL);
  return (
    <>
      <Tabs
        classes={{
          root: classes.roots,
          indicator: classes.tabsIndicator, // Hide the indicator
        }}
        value={activeIdx}
        onChange={handleChange}
        variant="scrollable"
        selectionFollowsFocus
        scrollButtons={matchesSmallScreen ? "on" : "auto"}
        aria-label="scrollable category menu items list"
        ScrollButtonComponent={(props) =>
          props.direction === "left" ? (
            <Fade in={!props.disabled}>
              <IconButtonDefault
                className={classes.arrow}
                aria-label="ArrowBackIosIcon"
                {...props}
              >
                <ChevronLeftIcon
                  className={classes.arrowIcon}
                  fontSize="large"
                />
              </IconButtonDefault>
            </Fade>
          ) : (
            <Fade in={!props.disabled}>
              <IconButtonDefault
                className={classes.arrow}
                aria-label="ArrowForwardIosIcon"
                {...props}
              >
                <ChevronRightIcon
                  className={classes.arrowIcon}
                  fontSize="large"
                />
              </IconButtonDefault>
            </Fade>
          )
        }
      >
        {isActivatedLoyalty && (
          <Tab
            key={String(0)}
            classes={{ root: classes.tabRoot }}
            label={
              <Typography
                variant="body2"
                style={{
                  display: "Flex",
                  alignItems: "center",
                }}
              >
                <EmojiEventsIcon
                  style={{ paddingRight: "8px", fontSize: "30px" }}
                />
                Rewards
              </Typography>
            }
            {...a11yProps(0)}
          />
        )}
        {isActivatedLoyalty && (
          <Tab
            key={String(1)}
            classes={{ root: classes.tabRoot }}
            label={
              <Typography
                variant="body2"
                style={{
                  display: "Flex",
                  alignItems: "center",
                }}
              >
                <span
                  className="material-symbols-outlined"
                  style={{ paddingRight: "8px", fontSize: "30px" }}
                >
                  qr_code
                </span>
                QR Code
              </Typography>
            }
            {...a11yProps(1)}
          />
        )}
        <Tab
          key={String(isActivatedLoyalty ? 2 : 0)}
          classes={{ root: classes.tabRoot }}
          label={
            <Typography
              variant="body2"
              style={{
                display: "Flex",
                alignItems: "center",
              }}
            >
              <AccountCircleIcon
                style={{ paddingRight: "8px", fontSize: "30px" }}
              />
              Account
            </Typography>
          }
          {...a11yProps(isActivatedLoyalty ? 2 : 0)}
        />
      </Tabs>

      {/* Tab Content */}
      <TabPanel value={activeIdx} index={2}>
        {!isEmailVerified && <EmailActivationError setIsLoad={setIsLoad} />}

        <div
          style={{
            padding: "20px 20px",
          }}
        >
          {!isOpenAddress ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: theme.palette.secondary.main,
                borderRadius: "10px",
                height: "168px",
                // borderBottomLeftRadius: "100% 100px",
                // borderBottomRightRadius: "100% 100px",
              }}
            >
              <div style={{ paddingTop: "14px" }}>
                <Typography
                  variant="h4"
                  align="center"
                  style={{ color: theme.palette.secondary.contrastText }}
                >
                  YOUR ACCOUNT
                </Typography>

                <Typography
                  variant="body1"
                  align="center"
                  style={{
                    color: theme.palette.secondary.contrastText,
                    fontWeight: "bold",
                    marginTop: "8px",
                  }}
                >
                  {auth.displayName}
                </Typography>

                <Typography
                  variant="body1"
                  align="center"
                  style={{
                    color: theme.palette.secondary.contrastText,
                    fontWeight: "bold",
                  }}
                >
                  {auth.email}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    top: 18,
                  }}
                >
                  {userImage ? (
                    <Avatar className={classes.avatar}>
                      <img
                        src={
                          "https://lh3.googleusercontent.com/a/ACg8ocJcx1drFvd2jvcqRFx-opEuhKJ2GPSbBz6i6rl-I33XCx6pPg=s96-c"
                        }
                        alt="User profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover", // Ensures the image covers the avatar fully
                          borderRadius: "50%", // Ensures the image is circular like the avatar
                        }}
                        onError={(e) => {
                          e.target.onerror = null; // Prevents infinite fallback
                          setUserImage(null); // Handle broken image fallback
                        }}
                      />
                    </Avatar>
                  ) : (
                    <Avatar
                      className={classes.avatar}
                      style={{
                        backgroundColor: colors[charIndex] || "#4CAF50", // Default to green
                      }}
                    >
                      {firstChar}
                    </Avatar>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button onClick={() => setIsOpenAddress(false)}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>
                      <ArrowBackIcon style={{paddingTop: "7px", paddingRight: "8px"}} />
                    </Typography>
                    <Typography>Back</Typography>
                  </div>
                </Button>
              </Grid>
              {!_.isEmpty(customerDetailsList) ? (
                customerDetailsList.map((data, index) => {
                  return (
                    <Grid item xs={12} md={6} xl={4} key={index}>
                      <div
                        className={classes.buttonStyle}
                        style={matches ? { width: "96%" } : { width: "100%" }}
                      >
                        {/* <Typography
                          alignItems="start"
                          style={{
                            paddingLeft: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          {data.firstName + " " + data.lastName}
                        </Typography>
                        <Typography
                          alignItems="start"
                          style={{
                            paddingLeft: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          {data.email}
                        </Typography>
                        <Typography
                          alignItems="start"
                          style={{
                            paddingLeft: "4px",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          {data.contactNo}
                        </Typography> */}
                        <div className={classes.addressStyle}>
                          <Typography
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {handleAddressFirstRaw(data)}
                          </Typography>
                          <Typography
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {handleAddressSecondRaw(data)}
                          </Typography>
                          <Typography
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              fontWeight: "bolder",
                              lineHeight: "24px",
                              fontSize: "18px",
                            }}
                          >
                            {data.postcode}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  );
                })
              ) : (
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: "5%",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "60px"
                  }}
                >
                  <Typography variant="h4">No saved addresses.</Typography>
                </Grid>
              )}
            </Grid>
          )}

         {!isOpenAddress&& <Card
            className={classes.logOutButton}
            style={{ marginTop: isOpenAddress ? "12px" : "70px" }}
            onClick={handleAddresses}
          >
            <Typography style={{ paddingTop: "4px" }}>
              <Icon
                icon="material-symbols:logout"
                height="24"
                width="24"
                style={{ paddingLeft: "20px" }}
              />
            </Typography>
            <Typography style={{ paddingLeft: "12px" }}>
              My addresses
            </Typography>
          </Card>}
          <Card
            className={classes.logOutButton}
            style={{ marginTop: "12px" }}
            onClick={handleLogout}
          >
            <Typography style={{ paddingTop: "4px" }}>
              <Icon
                icon="material-symbols:logout"
                height="24"
                width="24"
                style={{ paddingLeft: "20px" }}
              />
            </Typography>
            <Typography style={{ paddingLeft: "12px" }}>Log me out</Typography>
          </Card>
        </div>
        {/* <div style={{ padding: "20px 20px" }}>
          <Typography variant="h4" align="center">
           
          </Typography>
          <Typography variant="body1" align="center">
          </Typography>

          <Typography variant="body1" align="center">
          
          </Typography>
          <Typography variant="body1" align="center">
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Avatar
              className={classes.avatar}
              style={{ backgroundColor: colors[charIndex] || "#4CAF50" }} // Default to green if out of range
            >
              {firstChar}
            </Avatar>
          </div>
          <Card
            elevation={8}
            style={{
              height: "48px",
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
              marginTop: "100px",
              paddingLeft: "20px",
              cursor: "pointer",
            }}
            onClick={handleLogout}
          >
            <Typography>Log me out</Typography>
          </Card>
        </div> */}
      </TabPanel>
      <TabPanel value={activeIdx} index={1}>
        {!isEmailVerified && <EmailActivationError setIsLoad={setIsLoad} />}
        <div
          style={{
            padding: "20px 20px",
            ...(isEmailVerified ? {} : blurStyle),
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: theme.palette.secondary.main,
              paddingBottom: "20px",
              borderRadius: "10px",
              // borderBottomLeftRadius: "100% 100px",
              // borderBottomRightRadius: "100% 100px",
            }}
          >
            <div style={{ paddingTop: "16px" }}>
              <Typography
                variant="h5"
                align="center"
                style={{ color: theme.palette.secondary.contrastText }}
              >
                YOUR CODE
              </Typography>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: theme.palette.secondary.contrastText,
                  fontWeight: "bold",
                }}
              >
                {shortId}
              </Typography>
            </div>
          </div>
          <Typography
            variant="h5"
            align="center"
            style={{
              marginTop: "30px",
            }}
          >
            Scan your QR code in-store
          </Typography>
          <Typography
            variant="h5"
            align="center"
            style={{
              marginBottom: "10px",
            }}
          >
            to collect stamps
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Box
              style={{
                border: `4px solid ${
                  isDark ? "transparent" : theme.palette.secondary.main
                }`,
                width: "240px",
                backgroundColor: "white",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  height: "200px",
                  width: "200px",
                  borderRadius: "10px",
                  overflow: "hidden", // Ensures the content respects the border radius
                  marginTop: "20px",
                  marginBottom: "20px",
                  marginLeft: "20px",
                }}
              >
                <QRCode
                  size={256}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  value={customerId}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </Box>
          </div>
        </div>
      </TabPanel>

      <TabPanel value={activeIdx} index={0}>
        {!isEmailVerified && <EmailActivationError setIsLoad={setIsLoad} />}

        <div
          style={{
            padding: "20px 20px",
            ...(isEmailVerified ? {} : blurStyle),
          }}
        >
          {!_.isEmpty(rewardNode?.rewards) ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: theme.palette.secondary.main,
                  paddingBottom: "16px",
                  borderRadius: "10px",
                  // borderBottomLeftRadius: "100% 100px",
                  // borderBottomRightRadius: "100% 100px",
                }}
              >
                <div style={{ paddingTop: "14px" }}>
                  <Typography
                    variant="h5"
                    align="center"
                    style={{ color: theme.palette.secondary.contrastText }}
                  >
                    YOUR STAMPS
                  </Typography>
                  <Typography
                    variant="h3"
                    align="center"
                    style={{
                      color: theme.palette.secondary.contrastText,
                      fontWeight: "bold",
                    }}
                  >
                    {rewardNode.points}
                  </Typography>
                </div>
              </div>
              <Grid container style={{ marginTop: "16px" }}>
                {rewardNode.rewards.map((value, index) => (
                  <>
                    {matches && (
                      <Grid item xs={1} style={{ marginTop: "2px" }}></Grid>
                    )}
                    <Grid
                      item
                      xs={matches ? 10 : 12}
                      style={{ marginTop: "2px" }}
                    >
                      {/* Left Semi-Circle Cut */}
                      <div
                        style={{
                          position: "relative",
                          background: buttonBackgroundColor,
                          border: `1px solid ${boarderColor}`,
                          borderRadius: "10px",
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            backgroundColor: popupBackgroundColor,
                            borderRadius: "0 10px 10px 0",
                            top: "50%",
                            left: "-1px",
                            transform: "translateY(-50%)",
                            width: "16px",
                            height: "32px" /* as the half of the width */,
                            borderTopRightRadius: "360px",
                            borderBottomRightRadius: "360px",
                            border: `1px solid ${boarderColor}`,
                            borderLeft: 0,
                          }}
                        ></div>

                        {/* Right Semi-Circle Cut */}
                        <div
                          style={{
                            position: "absolute",
                            backgroundColor: popupBackgroundColor,
                            borderRadius: "0 10px 10px 0",
                            top: "50%",
                            right: "-1px",
                            transform: "translateY(-50%)",
                            width: "12px",
                            height: "30px" /* as the half of the width */,
                            borderTopLeftRadius: "360px",
                            borderBottomLeftRadius: "360px",
                            border: `1px solid ${boarderColor}`,
                            borderRight: 0,
                          }}
                        ></div>
                        <Grid container>
                          <Grid item xs={4}>
                            {value?.img ? (
                              <div
                                style={{
                                  width: "68%",
                                  height: "84%",
                                  borderRadius: "50%",
                                  overflow: "hidden",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginLeft: "12px",
                                  marginTop: "12px",
                                  marginBottom: "40px",
                                }}
                              >
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    backgroundSize: "cover",
                                  }}
                                  src={value.img}
                                  alt="reward"
                                />
                              </div>
                            ) : isDark ? (
                              <img
                                width="82%"
                                height="92%"
                                src={placeholderDark}
                                alt="reward"
                              />
                            ) : (
                              <img
                                width="82%"
                                height="92%"
                                src={placeholderLight}
                                alt="reward"
                              />
                            )}
                          </Grid>
                          <Grid item xs={8} style={{ paddingTop: "8px" }}>
                            <Typography
                              style={{
                                color: popupTextColor,
                                fontWeight: "bold",
                              }}
                              variant="h5"
                            >
                              {value.title}
                            </Typography>
                            <Typography
                              style={{
                                color: popupTextColor,
                                paddingTop: "8px",
                              }}
                              variant="body2"
                            >
                              {value.description}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{ marginTop: value?.img ? "16px" : "4px" }}
                          >
                            <Divider className={classes.divider} />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{ paddingTop: "12px", paddingLeft: "20px" }}
                          >
                            <Typography variant="body2">
                              {value.state === "pending" &&
                              value.stamped_spaces === 0
                                ? "Collect more stamps to redeem"
                                : value.state === "ready"
                                ? "Ready to Redeem"
                                : value.state === "used"
                                ? `Redeemed on ${moment(value.used_date).format(
                                    "Do MMMM YYYY",
                                  )}`
                                : `${
                                    value.no_of_spaces - value.stamped_spaces
                                  } more stamps to go`}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              paddingTop: "6px",
                              paddingBottom: "8px",
                              paddingLeft: "20px",
                            }}
                          >
                            {handleCircle(
                              value.no_of_spaces,
                              value.state,
                              value.stamped_spaces,
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: "4px" }}></Grid>
                  </>
                ))}
              </Grid>
            </>
          ) : (
            <Typography
              variant="h4"
              style={{
                marginTop: "80px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Rewards Not Available.
            </Typography>
          )}
        </div>
      </TabPanel>
    </>
  );
};

export default UserDetailsModal;
