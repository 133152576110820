import * as React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import fbIcon from "../../../../../assets/icons/fb.png";
import firebase from "../../../../../utils/firebase";
import SocialSignInButton from "../../../../common/buttons/SocialSignInButton";
import {
  customerUpdateNew,
  getCustomerValidationNew,
} from "../../../../../services/customerService";
import { useDispatch, useSelector } from "react-redux";
import { updateLocalStorage } from "../../../../../utils/UpdateLocalStorage";
import { setLocalStore } from "../../../../../utils/utils";

const ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST =
  "An account already exists with the same email address via Google sign-in. Please use Google to sign-in";

const ERROR_MESSAGE_IDB_DATABASE =
  "Oops! Something went wrong while trying to sign you in. Please try again in a few seconds. If the issue persists, you can refresh your browser, use a different sign-in method, or continue as a guest. We apologise for the inconvenience.";

const FacebookSignIn = ({
  setAuthError,
  isNeedDisabled,
  setIsEmptyLastName,
  setIssOpenFirstNameLastNameModal,
  setFacebookFirstName,
  setToken,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();
  const { params } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const isActiveLoyalty = useSelector(
    (state) => state.loyalty[`isActiveLoyalty_${params.locationId}`],
  );
  const handleClick = async () => {
    if (isNeedDisabled) return;

    try {
      resetErrorState();
      setIsLoading(true);

      const user = await handleFacebookSignIn();
      const token = await user.getIdToken(true);

      await processCustomerValidation(token, user);
    } catch (error) {
      handleAuthError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to reset error and loading states
  const resetErrorState = () => {
    setAuthError("");
    setIsLoading(true);
  };

  // Helper function to handle Facebook Sign-In
  const handleFacebookSignIn = async () => {
    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      const result = await firebase.auth().signInWithPopup(provider);
      return result.user;
    } catch (error) {
      throw error; // Pass error to the caller for handling
    }
  };

  // Helper function to validate customer and handle post-auth actions
  const processCustomerValidation = async (token, user) => {
    try {
      const response = await getCustomerValidationNew(token); // Validate customer
      setLocalStore("customerId", response.data.data.id);
      setLocalStore("shortId", response.data.data.shortId);
      logSignInSuccess(user);

      // Redirect after authentication
      updateLocalStorage(
        token,
        dispatch,
        user,
        history,
        params,
        location,
        isActiveLoyalty,
      );
    } catch (error) {
      await handleCustomerRegistration(token, user); // Register customer if validation fails
    }
  };

  // Helper function to log successful sign-in
  const logSignInSuccess = (user) => {
    logInfo({
      message: `FacebookSignIn: User [name: ${user.displayName}, uid: ${user.uid}] login success`,
    });
  };

  // Helper function to handle customer registration if validation fails
  const handleCustomerRegistration = async (token, user) => {
    const fullName = user?.displayName || "";
    const [firstName, ...lastNameParts] = fullName.split(" ");
    const lastName = lastNameParts.join(" ") || "";

    if (!lastName) {
      promptForLastName(firstName, token);
    } else {
      await registerNewCustomer(token, firstName, lastName, user);
    }
  };

  // Helper function to prompt user for last name if missing
  const promptForLastName = (firstName, token) => {
    setIsEmptyLastName(true);
    setIssOpenFirstNameLastNameModal(true);
    setFacebookFirstName(firstName);
    setToken(token);
    setIsLoading(false);
  };

  // Helper function to register a new customer
  const registerNewCustomer = async (token, firstName, lastName, user) => {
    try {
      const response = await customerUpdateNew(token, firstName, lastName);
      setLocalStore("customerId", response.data.data.id);
      setLocalStore("shortId", response.data.data.shortId);
      logSignInSuccess({
        displayName: `${firstName} ${lastName}`,
        uid: firebase.auth().currentUser?.uid,
      });

      updateLocalStorage(
        token,
        dispatch,
        user,
        history,
        params,
        location,
        isActiveLoyalty,
      );
    } catch (error) {
      throw error; // Let the calling function handle this error
    }
  };

  // Helper function to handle authentication errors
  const handleAuthError = (error) => {
    const { email, credential, code: errorCode, message: errorMessage } = error;
    const isIdbError = errorMessage.includes("IDBDatabase");

    const message = isIdbError
      ? ERROR_MESSAGE_IDB_DATABASE
      : errorCode === "auth/account-exists-with-different-credential"
      ? ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST
      : errorMessage;

    setAuthError(message);
    logError({
      message: "FacebookSignIn: Error login user with Facebook sign in",
      error,
      email,
      credential,
      errorCode,
    });
  };

  // Log and Error Utility
  const logError = (errorDetails) => {
    // Implementation for logging errors
    console.error(errorDetails);
  };

  const logInfo = (infoDetails) => {
    // Implementation for logging info
    console.info(infoDetails);
  };

  return (
    <SocialSignInButton
      handleClick={handleClick}
      logoSrc={fbIcon}
      labelText="Sign in with Facebook"
      alt="Facebook sign in"
      isLoading={isLoading}
      disabled={isNeedDisabled}
    />
  );
};

export default FacebookSignIn;
