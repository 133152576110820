import { applyMiddleware, combineReducers, createStore } from "redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from "redux-devtools-extension";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import reduxReset from "redux-reset";
import thunkMiddleware from "redux-thunk";

import InitDataReducer, { InitDataInitialState } from "../app/redux/reducer";
import ContactReducer, {
  ContactInitialState,
} from "../components/@pages/contact/redux/reducer";
import PaymentsReducer, {
  PaymentsInitialState,
} from "../components/@pages/payments/redux/reducer";
import {
  CatMenuListInitialState,
  CatMenuListReducer,
  CustomerDetailListReducer,
  loginModalReducer,
  LoyaltyListReducer,
  RewardDetailListReducer,
} from "../components/@pages/storeHome/CatMenu/redux/reducer";
import {
  ItemInitialState,
  ItemReducer,
} from "../components/@pages/storeHome/ItemList/redux/reducer";
import {
  CategoryInitialState,
  CategoryReducer,
} from "../components/@pages/storeHome/Menu/redux/reducer";
import {
  CartInitialState,
  CartReducer,
} from "../components/@pages/storeHome/MyOrder/redux/reducer";
import StoreReducer, {
  StoreInitialState,
} from "../components/@pages/storeHome/redux/reducer";
import RouteReducer, {
  RouteInitialState,
} from "../components/routes/redux/reducer";
import AuthReducer, { AuthInitialState } from "./reducers/auth";
import GlobalReducer, { GlobalInitialState } from "./reducers/global";

const getLocationIdByPathname = () =>
  window.location.pathname.split("location/")[1].split("/")[0];

const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    const store = JSON.stringify(inboundState);
    const locationId = getLocationIdByPathname();
    if (key === "cart") {
      localStorage[`cart_${locationId}`] = store;
    }
    if (key === "contact") {
      localStorage[`contact_${locationId}`] = store;
    }
    if (key === "payments") {
      localStorage[`payments_${locationId}`] = store;
    }
    if (key === "route") {
      localStorage[`route_${locationId}`] = store;
    }
    // convert mySet to an Array.
    return { ...inboundState };
  },
  // transform state being rehydrated
  (outboundState, key) => {
    const locationId = getLocationIdByPathname();
    // convert mySet back to a Set.
    if (key === "cart") {
      const store = JSON.parse(localStorage[`cart_${locationId}`]);
      return { ...store };
    }
    if (key === "contact") {
      const store = JSON.parse(localStorage[`contact_${locationId}`]);
      return { ...store };
    }
    if (key === "payment") {
      const store = JSON.parse(localStorage[`payment_${locationId}`]);
      return { ...store };
    }
    if (key === "route") {
      const store = JSON.parse(localStorage[`route_${locationId}`]);
      return { ...store };
    }
    return { ...outboundState };
  },
  // define which reducers this transform gets called for.
  { whitelist: ["cart", "contact", "payment", "route"] },
);

const persistConfig = {
  key: "root",
  blacklist: ["catMenu", "category", "item", "initData", "store", "global"],
  storage,
  transforms: [SetTransform],
};

const getInitialState = () => ({
  item: ItemInitialState,
  category: CategoryInitialState,
  cart: CartInitialState,
  catMenu: CatMenuListInitialState,
  initData: InitDataInitialState,
  store: StoreInitialState,
  auth: AuthInitialState,
  payments: PaymentsInitialState,
  contact: ContactInitialState,
  route: RouteInitialState,
  global: GlobalInitialState,
});

const rootReducer = combineReducers({
  item: ItemReducer,
  category: CategoryReducer,
  cart: CartReducer,
  catMenu: CatMenuListReducer,
  loyalty: LoyaltyListReducer,
  rewardDetails: RewardDetailListReducer,
  customerDetails: CustomerDetailListReducer,
  isOpenRewardModal: loginModalReducer,
  initData: InitDataReducer,
  store: StoreReducer,
  auth: AuthReducer,
  payments: PaymentsReducer,
  contact: ContactReducer,
  route: RouteReducer,
  global: GlobalReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore() {
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer, reduxReset()];
  const composedEnhancers = composeWithDevTools(...enhancers);
  const store = createStore(
    persistedReducer,
    getInitialState(),
    composedEnhancers,
  );
  return store;
}

const store = configureStore();
export const persistor = persistStore(store);
export default store;
