import React, { useEffect } from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useCallback } from "react";
import ButtonDefault from "../../../common/buttons/ButtonDefault";
import firebase from "firebase";
import { logError } from "../../../../services/logging";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import FacebookSignIn from "./FacebookSignIn";
import GoogleSignIn from "./GoogleSignIn";
import TextFieldCommon from "../../../common/textFields/TextFieldCommon";
import { isFunction } from "lodash";
import { setIsOpenLoginModal } from "../../../../redux/actions/auth";
import { setLocalStore } from "../../../../utils/utils";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import PasswordResetModal from "./PasswordResetModal";
import AlertMessage from "../../../common/AlertMessage";
import {
  customerUpdateNew,
  getCustomerValidationNew,
} from "../../../../services/customerService";
import FirstNameLastNameModal from "./FirstNameLastNameModal";
import { updateLocalStorage } from "../../../../utils/UpdateLocalStorage";

const ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST =
  "An account already exists with the same email address via Google sign-in. Please use Google to sign-in";

const ERROR_MESSAGE_IDB_DATABASE =
  "Oops! Something went wrong while trying to sign you in. Please try again in a few seconds. If the issue persists, you can refresh your browser, use a different sign-in method, or continue as a guest. We apologise for the inconvenience.";

const useStyles = makeStyles((theme) => ({
  makeMiddle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    backgroundColor: "white",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    },
  },
  textStyle: {
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));

const SignInPage = () => {
  const [customer, setCustomer] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isNeedDisabled, setIsNeedDisabled] = useState(false);
  const [authError, setAuthError] = useState("");
  const [isOpenPasswordResetNodal, setIsOpenPasswordResetNodal] =
    useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [issOpenFirstNameLastNameModal, setIssOpenFirstNameLastNameModal] =
    useState(false);
  const [token, setToken] = useState("");
  const [isEmptyLastName, setIsEmptyLastName] = useState(false);
  const theme = useTheme();
  const { register, errors, trigger } = useForm();
  const [googleFirstName, setGoogleFirstName] = useState("");
  const history = useHistory();
  const { params } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.auth);
  const isActiveLoyalty = useSelector(
    (state) => state.loyalty[`isActiveLoyalty_${params.locationId}`],
  );
  useEffect(() => {
    if (isAuthenticated) {
      if (window.authCallback && isFunction(window.authCallback)) {
        window.authCallback();
        window.authCallback = null;
      }
      dispatch(setIsOpenLoginModal(false));
      history.push({
        pathname: `/location/${params.locationId}/shop`,
        search: location.search,
      });
    }
  }, [isAuthenticated]);

  const trimValues = useCallback(async () => {
    setCustomer({
      email: customer.email.trim(),
      password: customer.password.trim(),
    });
  }, [customer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "marketing") {
      if (value === "true") {
        setCustomer({ ...customer, [name]: false });
      } else {
        setCustomer({ ...customer, [name]: true });
      }
    } else {
      setCustomer({ ...customer, [name]: value });
    }
  };

  // Main login handler
  const handleLogin = async () => {
    try {
      await setAuthPersistence();

      setIsLoading(true);
      setIsNeedDisabled(true);

      const firebaseUser = await signInUser(customer.email, customer.password);

      if (firebaseUser) {
        const idToken = await firebaseUser.getIdToken(true);

        await handlePostLogin(firebaseUser, idToken);
      }
    } catch (error) {
      handleAuthError(error);
    } finally {
      setIsLoading(false);
      setIsNeedDisabled(false);
    }
  };

  // Helper function to set Firebase authentication persistence
  const setAuthPersistence = async () => {
    try {
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    } catch (error) {
      handleAuthError(error);
    }
  };

  // Helper function to sign in user using email and password
  const signInUser = async (email, password) => {
    try {
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      return result.user;
    } catch (error) {
      throw error; // Let the caller handle the error
    }
  };

  // Post-login handling: validate user and redirect
  const handlePostLogin = async (firebaseUser, token) => {
    try {
      await validateCustomer(firebaseUser, token);
    } catch (error) {
      if (firebaseUser?.displayName) {
        await registerNewCustomer(firebaseUser, token);
      } else {
        setIssOpenFirstNameLastNameModal(true); // Prompt user for additional info if necessary
        setToken(token);
      }
    }
  };

  // Helper function to validate customer existence
  const validateCustomer = async (firebaseUser, token) => {
    try {
      const response = await getCustomerValidationNew(token); // Assuming this validates the customer in your
      setLocalStore("customerId", response.data.data.id);
      setLocalStore("shortId", response.data.data.shortId);
      if (response.data.data.firstName && response.data.data.lastName) {
        if (!firebaseUser.displayName) {
          await firebaseUser.updateProfile({
            displayName: `${response.data.data.firstName} ${response.data.data.lastName}`,
          });
        }
        await finalizeLogin(response.data.data, token);
      } else {
        setIssOpenFirstNameLastNameModal(true);
        setToken(token);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        await registerNewCustomer(firebaseUser, token);
      }
      throw error; // Rethrow unexpected errors
    }
  };

  const promptForLastName = (firstName, token) => {
    setIsEmptyLastName(true);
    setIssOpenFirstNameLastNameModal(true);
    setGoogleFirstName(firstName);
    setToken(token);
    setIsLoading(false);
  };

  // Helper function to register a new customer
  const registerNewCustomer = async (firebaseUser, token) => {
    const fullName = firebaseUser.displayName || "";
    const [firstName, ...lastNameParts] = fullName.split(" ");
    const lastName = lastNameParts.join(" ") || "";

    try {
      if (!lastName) {
        promptForLastName(firstName, token);
      }
      const response = await customerUpdateNew(token, firstName, lastName);
      setLocalStore("customerId", response.data.data.id);
      setLocalStore("shortId", response.data.data.shortId);
      await firebaseUser.updateProfile({
        displayName: `${firstName} ${lastName}`,
      });
      await finalizeLogin(firebaseUser, token);
    } catch (error) {
      throw error;
    }
  };

  // Helper function to finalize the login process
  const finalizeLogin = async (firebaseUser, token) => {
    updateLocalStorage(
      token,
      dispatch,
      firebaseUser,
      history,
      params,
      location,
      isActiveLoyalty,
    );
  };

  // Error handling function
  const handleAuthError = (error) => {
    console.error("Error during login:", error);
    const errorMessage = error.message.includes("IDBDatabase")
      ? ERROR_MESSAGE_IDB_DATABASE
      : error.message;
    setAuthError(errorMessage);

    logError({
      message: "Firebase SignIn: Error during login",
      error,
      email: error.email,
      credential: error.credential,
      errorCode: error.code,
    });

    setIsLoading(false);
    setIsNeedDisabled(false);
  };

  const handleContinue = async () => {
    setAuthError("");
    // Trim white spaces from the form values.
    trimValues();

    // Trigger form validation using the useForm trigger function.
    // This will validate all the registered fields and return a boolean indicating if the form is valid.
    const isValid = await trigger();

    // If the form is not valid, return early and do not proceed.
    if (!isValid) return;

    handleLogin();
  };

  const handleOpenPasswordResetModal = () => {
    setIsOpenPasswordResetNodal(true);
  };

  const handleRedirectRegisterPage = () => {
    history.push({
      pathname: `/location/${params.locationId}/register`,
      search: location.search,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              align="center"
              style={{ fontWeight: "bold" }}
            >
              Sign In
            </Typography>
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={12} sm={8} md={6}>
            <TextFieldCommon
              id="email"
              name="email"
              label="Email address"
              value={customer.email}
              variant="outlined"
              onChange={handleChange}
              fullWidth
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
              helperText={errors.email ? "Please enter email address" : ""}
              error={errors.email?.type}
            />
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>{" "}
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={12} sm={8} md={6} style={{ marginTop: "4px" }}>
            <TextFieldCommon
              id="password"
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              value={customer.password}
              variant="outlined"
              onChange={handleChange}
              fullWidth
              // style={{ color: "black" }}
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
              // InputLabelProps={{
              //   style: { color: "black" },
              // }}
              InputProps={{
                // style: { color: "black" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={errors.password ? "Please enter password" : ""}
              error={errors.password?.type}
            />
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            style={{ display: "flex", justifyContent: "start" }}
          >
            <Button>
              <Typography
                variant="body2"
                gutterBottom
                align="center"
                style={{
                  fontWeight: "bold",
                  textTransform: "none",
                }}
                onClick={handleOpenPasswordResetModal}
              >
                Forgot Password?
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={12} sm={8} md={6} style={{ marginTop: "24px" }}>
            <ButtonDefault onClick={handleContinue} fullWidth>
              <Button
                style={{
                  marginLeft: "8px",
                  padding: "4px",
                  minWidth: "40px",
                }}
                onClick={handleContinue}
              >
                <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {isLoading ? <CircularProgress size={20} /> : "LOGIN"}
                </Typography>
              </Button>
            </ButtonDefault>
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            style={{ marginTop: "8px", alignItems: "center" }}
          >
            <Grid container style={{ display: "flex", alignItems: "center" }}>
              <Grid item xs={3} sm={4}>
                <Divider
                  style={{
                    margin: "4px 0",
                    backgroundColor: theme.palette.primary.contrastText,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography className={classes.textStyle}>
                  or LogIn in with
                </Typography>
              </Grid>
              <Grid item xs={3} sm={4}>
                <Divider
                  style={{
                    margin: "4px 0",
                    backgroundColor: theme.palette.primary.contrastText,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={8} md={6} style={{ marginTop: "24px" }}>
            <div>
              <div style={{ padding: "4px 0" }}>
                {authError && (
                  <MuiAlert
                    style={{ marginBottom: "12px" }}
                    severity="error"
                    elevation={6}
                    variant="outlined"
                  >
                    {authError}
                  </MuiAlert>
                )}
              </div>

              <Grid
                container
                className={classes.makeMiddle}
                spacing={2}
                style={{ marginTop: 8 }}
              >
                <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
                  <GoogleSignIn
                    setAuthError={setAuthError}
                    isNeedDisabled={isNeedDisabled}
                    setIsEmptyLastName={setIsEmptyLastName}
                    setIssOpenFirstNameLastNameModal={
                      setIssOpenFirstNameLastNameModal
                    }
                    setGoogleFirstName={setGoogleFirstName}
                    setToken={setToken}
                  />
                </Grid>
                <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
                  <FacebookSignIn
                    setAuthError={setAuthError}
                    isNeedDisabled={isNeedDisabled}
                    setIsEmptyLastName={setIsEmptyLastName}
                    setIssOpenFirstNameLastNameModal={
                      setIssOpenFirstNameLastNameModal
                    }
                    setFacebookFirstName={setGoogleFirstName}
                    setToken={setToken}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={8} md={6}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Typography
                style={{
                  fontSize: "18px",
                }}
              >
                Don't have an account?
              </Typography>
              <Button
                onClick={handleRedirectRegisterPage}
                style={{ textTransform: "none" }}
              >
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    paddingLeft: "8px",
                  }}
                >
                  Register
                </Typography>
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <PasswordResetModal
        setOpenDialog={setIsOpenPasswordResetNodal}
        openDialog={isOpenPasswordResetNodal}
        setIsLoading={setIsLoading}
        setError={setError}
        isLoading={isLoading}
        setAlertMessage={setAlertMessage}
      />

      <FirstNameLastNameModal
        setOpenDialog={setIssOpenFirstNameLastNameModal}
        openDialog={issOpenFirstNameLastNameModal}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        token={token}
        email={customer.email}
        isEmptyLastName={isEmptyLastName}
        googleFirstName={googleFirstName}
      />

      <AlertMessage
        message={alertMessage}
        setMessage={setAlertMessage}
        severity="error"
        anchorOrigin="bottom"
      />
    </>
  );
};

export default SignInPage;
