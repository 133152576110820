import React from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { customerUpdateNew } from "../../../../../services/customerService";
import { setLocalStore } from "../../../../../utils/utils";
import {
  loginUserSuccess,
  setIsOpenLoginModal,
} from "../../../../../redux/actions/auth";
import { useDispatch } from "react-redux";
import firebase from "firebase";
import TextFieldCommonModal from "../../../../common/textFields/TextFieldCommonModal";
import { logError, logInfo } from "../../../../../services/logging";
import {
  getLocalStore,
  removeLocalStore,
} from "../../../../../utils/storage/localStorage";

const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: theme?.view?.isFixedList ? "initial" : "100%",
      },
    },
    "& .MuiDialog-paper": {
      margin: 4,
    },
  },
}));

const FirstNameLastNameModal = ({
  setOpenDialog,
  openDialog,
  setIsLoading,
  isLoading,
  token,
  email,
  isEmptyLastName,
  googleFirstName,
  setCustomerDetails,
  setFirebaseUser,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const { register, errors, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        logInfo({
          message: `User ${getLocalStore("userId")} logged out success`,
        });

        removeLocalStore("idToken");
        removeLocalStore("username");
        removeLocalStore("userId");
        window.EATPRESTO.postMessage("logout_success");
      })
      .catch((error) => {
        logError({
          message: `Error logging out user ${getLocalStore("userId")}`,
          error,
        });
      });
  };

  const handleCancel = () => {
    setOpenDialog(false);
    handleLogout();
  };

  // Function to get the current user
  const getCurrentUser = () => {
    return firebase.auth().currentUser;
  };

  const onSubmit = async () => {
    const firebaseUser = getCurrentUser(); // Get the currently signed-in user

    if (!isEmptyLastName) {
      setIsLoading(true);
      const response = await customerUpdateNew(token, firstName, lastName);
      setLocalStore("customerId", response.data.data.id);
      setLocalStore("shortId", response.data.data.shortId);
      if (!firebaseUser.emailVerified) {
        setFirebaseUser(firebaseUser);
        setCustomerDetails({ firstName, lastName, email });
      } else {
        setLocalStore("idToken", token);
        dispatch(setIsOpenLoginModal(false));
        dispatch(loginUserSuccess(`${firstName} ${lastName}`, email));
        await firebaseUser.updateProfile({
          displayName: `${firstName} ${lastName}`,
        });
      }
    } else {
      setIsLoading(true);
      const response = await customerUpdateNew(
        token,
        googleFirstName,
        lastName,
      );
      setLocalStore("customerId", response.data.data.id);
      setLocalStore("shortId", response.data.data.shortId);
      await firebaseUser.updateProfile({
        displayName: `${googleFirstName} ${lastName}`,
      });

      if (!firebaseUser.emailVerified) {
        setFirebaseUser(firebaseUser);
        setCustomerDetails({ firstName, lastName, email });
      } else {
        setLocalStore("idToken", token);
        dispatch(setIsOpenLoginModal(false));
        dispatch(loginUserSuccess(`${firstName} ${lastName}`, email));
      }
    }
  };
  const handleChangeFirstName = (e) => {
    const { value } = e.target;
    setFirstName(value);
  };

  const handleChangeLastName = (e) => {
    const { value } = e.target;
    setLastName(value);
  };

  const classes = useStyles();

  return (
    <Dialog
      classes={{ root: classes.modal }}
      open={openDialog}
      onClose={(e, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        setOpenDialog(false);
      }}
      keepMounted
      minWidth="md"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {/* <DialogTitle>Reset Password</DialogTitle> */}
      <DialogContent>
        <Grid container>
          {!isEmptyLastName && (
            <Grid item xs={12}>
              <TextFieldCommonModal
                id="firstName"
                name="firstName"
                label="First Name"
                type="text"
                value={firstName}
                variant="outlined"
                onChange={handleChangeFirstName}
                fullWidth
                inputRef={register({
                  required: true,
                  minLength: 2,
                  maxLength: 30,
                })}
                helperText={errors.firstName ? "Please enter First Name" : ""}
                error={errors.firstName?.type}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextFieldCommonModal
              id="lastName"
              name="lastName"
              label="Last Name"
              type="text"
              value={lastName}
              variant="outlined"
              onChange={handleChangeLastName}
              fullWidth
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
              helperText={errors.lastName ? "Please enter Last Name" : ""}
              error={errors.lastName?.type}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleCancel} disabled={isLoading} color="inherit">
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)} color="inherit" autoFocus>
          {isLoading ? <CircularProgress size={20} color="inherit" /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FirstNameLastNameModal;
