import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useForm } from "react-hook-form";
import GoogleSignIn from "../GoogleSignIn";
import FacebookSignIn from "../FacebookSignIn";
import { setIsOpenLoginModal } from "../../../../../redux/actions/auth";
import FirstNameLastNameModal from "../../../signInPage/SignInPage/FirstNameLastNameModal";
import { handleSignIn } from "../../../../../utils/EmailPasswordSignIn";
import TextFieldDefault from "../../../../common/textFields/TextFieldDefault";
import ContentContainer from "../../../../../containers/ContentContainer";
import StickyButton from "../../../../common/buttons/StickyButton";
import { handleSignInNew } from "../../../../../utils/EmailPasswordSignInNew";
import { getLocalStore } from "../../../../../utils/storage/localStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperFullWidth": {
      borderRadius: theme.shape.borderRadius,
    },
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: theme?.view?.isFixedList ? "initial" : "100%",
      },
    },
  },
  form: {
    padding: theme.spacing(1, 1, 0, 1),
    display: "flex",
    flexDirection: "column",
    // color: theme.palette.text.primaryTextColor,
  },
  titleSection: {
    padding: theme.spacing(2),
    textAlign: "center",
    // color: theme.palette.text.primaryTextColor,
  },
  makeMiddle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textStyle: {
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));

const SignInPageNew = ({
  setIsOpenPasswordResetNodal,
  handleChangeLoginStatus,
  setAuthError,
  setIsOpenEmailVerificationLoadingModal,
  setCustomerDetails,
  isOpenEmailVerificationLoadingModal,
  customerDetails,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNeedDisabled, setIsNeedDisabled] = useState(false);
  const [issOpenFirstNameLastNameModal, setIssOpenFirstNameLastNameModal] =
    useState(false);
  const [isEmptyLastName, setIsEmptyLastName] = useState(false);
  const [firebaseUser, setFirebaseUser] = useState(false);
  const [customer, setCustomer] = useState({
    email: "",
    password: "",
  });
  const history = useHistory();
  const { params } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const { register, errors, trigger } = useForm();
  const [googleFirstName, setGoogleFirstName] = useState("");
  const [token, setToken] = useState("");
  const { isAuthenticated, isOpenLoginModal } = useSelector(
    (state) => state.auth,
  );
  const idToken = getLocalStore("idToken");
  const isActiveLoyalty = useSelector(
    (state) => state.loyalty[`isActiveLoyalty_${params.locationId}`],
  );
  const classes = useStyles();

  useEffect(() => {
    setAuthError("");
  }, [isOpenLoginModal]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "marketing") {
      if (value === "true") {
        setCustomer({ ...customer, [name]: false });
      } else {
        setCustomer({ ...customer, [name]: true });
      }
    } else {
      setCustomer({ ...customer, [name]: value });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    handleSignInNew(
      setIsLoading,
      setIsNeedDisabled,
      customer,
      setIssOpenFirstNameLastNameModal,
      setToken,
      setIsEmptyLastName,
      setGoogleFirstName,
      setAuthError,
      dispatch,
      history,
      params,
      location,
      setIsOpenEmailVerificationLoadingModal,
      setCustomerDetails,
      isActiveLoyalty,
    );
  };

  const trimValues = useCallback(async () => {
    setCustomer({
      email: customer.email.trim(),
      password: customer.password.trim(),
    });
  }, [customer]);

  const handleContinue = async () => {
    setAuthError("");
    // Trim white spaces from the form values.
    trimValues();

    // Trigger form validation using the useForm trigger function.
    // This will validate all the registered fields and return a boolean indicating if the form is valid.
    const isValid = await trigger();

    // If the form is not valid, return early and do not proceed.
    if (!isValid) return;

    handleLogin();
  };

  const handleOpenPasswordResetModal = () => {
    setIsOpenPasswordResetNodal(true);
  };

  const theme = useTheme();
  return (
    <>
    
        <ContentContainer>
          <Grid
            container
            spacing={2}
            className={classes.makeMiddle}
            style={{ marginTop: 8 }}
          >
            <Grid item xs={12}>
              <TextFieldDefault
                id="email"
                name="email"
                label="Email address"
                value={customer.email}
                onChange={handleChange}
                variant="filled"
                fullWidth
                inputRef={register({
                  required: true,
                  minLength: 2,
                  maxLength: 80,
                })}
                InputLabelProps={{
                  style: { color: "black" },
                }}
                InputProps={{
                  style: { color: "black" },
                }}
                helperText={errors.email ? "Please enter email address" : ""}
                error={errors.email?.type}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldDefault
                id="password"
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                value={customer.password}
                variant="filled"
                onChange={handleChange}
                fullWidth
                inputRef={register({
                  required: true,
                  minLength: 2,
                  maxLength: 80,
                })}
                InputLabelProps={{
                  style: { color: "black" },
                }}
                InputProps={{
                  style: { color: "black" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon style={{ color: "black" }} />
                        ) : (
                          <VisibilityOffIcon style={{ color: "black" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={errors.password ? "Please enter password" : ""}
                error={errors.password?.type}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "start" }}
            >
              <Button disabled={isAuthenticated}>
                <Typography
                  variant="body2"
                  gutterBottom
                  align="center"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    textTransform: "none",
                  }}
                  onClick={handleOpenPasswordResetModal}
                >
                  Forgot Password?
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <StickyButton
                flex={2}
                style={{
                  width: "100%",
                  height: "40px",
                }}
                onClick={handleContinue}
                disabled={isLoading || isAuthenticated}
              >
                {isLoading ? (
                  <CircularProgress size={20} color="primary" />
                ) : (
                  "LOGIN"
                )}
              </StickyButton>
            </Grid>
            <Grid item xs={12}>
              <Grid container style={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={3} sm={4}>
                  <Divider
                    style={{
                      margin: "4px 0",
                      backgroundColor: theme.palette.primary.contrastText,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Typography className={classes.textStyle}>
                    or LogIn in with
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={4}>
                  <Divider
                    style={{
                      margin: "4px 0",
                      backgroundColor: theme.palette.primary.contrastText,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
              <GoogleSignIn
                setAuthError={setAuthError}
                isNeedDisabled={isNeedDisabled}
                setIsEmptyLastName={setIsEmptyLastName}
                setIssOpenFirstNameLastNameModal={
                  setIssOpenFirstNameLastNameModal
                }
                setGoogleFirstName={setGoogleFirstName}
                setToken={setToken}
              />
            </Grid>
            <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
              <FacebookSignIn
                setAuthError={setAuthError}
                isNeedDisabled={isNeedDisabled}
                setIsEmptyLastName={setIsEmptyLastName}
                setIssOpenFirstNameLastNameModal={
                  setIssOpenFirstNameLastNameModal
                }
                setFacebookFirstName={setGoogleFirstName}
                setToken={setToken}
              />
            </Grid>
            {/* <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
        <FirebaseAnonymousSignIn
          setAuthError={setAuthError}
          isAuthenticated={isAuthenticated}
        />
      </Grid> */}

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "18px",
                  }}
                >
                  Don't have an account?
                </Typography>
                <Button
                  onClick={handleChangeLoginStatus}
                  style={{ textTransform: "none" }}
                  disabled={isAuthenticated}
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      paddingLeft: "8px",
                      color: "black",
                    }}
                  >
                    Register
                  </Typography>
                </Button>
              </div>
            </Grid>

            <FirstNameLastNameModal
              setOpenDialog={setIssOpenFirstNameLastNameModal}
              openDialog={issOpenFirstNameLastNameModal}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              token={token}
              email={customer.email}
              isEmptyLastName={isEmptyLastName}
              googleFirstName={googleFirstName}
              setCustomerDetails={setCustomerDetails}
              setFirebaseUser={setFirebaseUser}
            />
          </Grid>
        </ContentContainer>
    </>
  );
};

SignInPageNew.propTypes = {};
SignInPageNew.defaultProps = {};

export default SignInPageNew;
