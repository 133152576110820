import * as React from "react";

import { isFunction } from "lodash";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import googleIcon from "../../../../../assets/images/googleSignIn/google.svg";
import firebase from "../../../../../utils/firebase";
import SocialSignInButton from "../../../../common/buttons/SocialSignInButton";
import { setLocalStore } from "../../../../../utils/storage/localStorage";
import {
  customerUpdateNew,
  getCustomerValidationNew,
} from "../../../../../services/customerService";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenLoginModal } from "../../../../../redux/actions/auth";
import { updateLocalStorage } from "../../../../../utils/UpdateLocalStorage";

const ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST =
  "An account already exists with the same email address via Facebook sign-in. Please use Facebook to sign-in";

const ERROR_MESSAGE_IDB_DATABASE =
  "Oops! Something went wrong while trying to sign you in. Please try again in a few seconds. If the issue persists, you can refresh your browser, use a different sign-in method, or continue as a guest. We apologise for the inconvenience.";

const GoogleSignIn = ({
  setAuthError,
  isNeedDisabled,
  setIsEmptyLastName,
  setIssOpenFirstNameLastNameModal,
  setGoogleFirstName,
  setToken,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();
  const { params } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const isActiveLoyalty = useSelector(
    (state) => state.loyalty[`isActiveLoyalty_${params.locationId}`],
  );
  const handleClick = async () => {
    if (isNeedDisabled) return;

    try {
      resetErrorState();
      setIsLoading(true);

      const user = await handleGoogleSignIn();
      const token = await user.getIdToken(true);

      await processCustomerValidation(token, user);
    } catch (error) {
      handleAuthError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to reset errors and loading states
  const resetErrorState = () => {
    setAuthError("");
    setIsLoading(true);
  };

  // Helper function to handle Google Sign-In
  const handleGoogleSignIn = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = await firebase.auth().signInWithPopup(provider);
      return result.user;
    } catch (error) {
      throw error; // Let the caller handle sign-in errors
    }
  };

  // Helper function to validate customer and handle post-auth actions
  const processCustomerValidation = async (token, user) => {
    try {
      const response = await getCustomerValidationNew(token); // Validate customer
      setLocalStore("customerId", response.data.data.id);
      setLocalStore("shortId", response.data.data.shortId);
      const fullName = user?.displayName || "";
      const [firstName, ...lastNameParts] = fullName.split(" ");
      const lastName = lastNameParts.join(" ") || "";

      if (!lastName) {
        promptForLastName(firstName, token);
      } else {
        logSignInSuccess({
          displayName: `${firstName} ${lastName}`,
          uid: firebase.auth().currentUser?.uid,
        });

        updateLocalStorage(
          token,
          dispatch,
          user,
          history,
          params,
          location,
          isActiveLoyalty,
        );
      }
    } catch (error) {
      await handleCustomerRegistration(token, user); // Register customer if validation fails
    }
  };

  // Helper function to log successful sign-in
  const logSignInSuccess = (user) => {
    logInfo({
      message: `GoogleSignIn: User [name: ${user.displayName}, uid: ${user.uid}] login success`,
    });
  };

  // Helper function to handle redirection after authentication
  const handlePostAuthRedirect = () => {
    setTimeout(() => {
      if (window.authCallback && isFunction(window.authCallback)) {
        window.authCallback();
        window.authCallback = null;
      }

      history.push({
        pathname: `/location/${params.locationId}/shop`,
        search: location.search,
      });

      setIsLoading(false);
    }, 1000);
  };

  // Helper function to handle customer registration if validation fails
  const handleCustomerRegistration = async (token, user) => {
    const fullName = user?.displayName || "";
    const [firstName, ...lastNameParts] = fullName.split(" ");
    const lastName = lastNameParts.join(" ") || "";

    if (!lastName) {
      promptForLastName(firstName, token);
    } else {
      await registerNewCustomer(token, firstName, lastName, user);
    }
  };

  // Helper function to prompt user for last name if missing
  const promptForLastName = (firstName, token) => {
    setIsEmptyLastName(true);
    setIssOpenFirstNameLastNameModal(true);
    setGoogleFirstName(firstName);
    setToken(token);
  };

  // Helper function to register a new customer
  const registerNewCustomer = async (token, firstName, lastName, user) => {
    try {
      const response = await customerUpdateNew(token, firstName, lastName);
      setLocalStore("customerId", response.data.data.id);
      setLocalStore("shortId", response.data.data.shortId);
      setLocalStore("idToken", token);
      setLocalStore("userId", user.uid);
      setLocalStore("photoURL", user?.photoURL);

      dispatch(setIsOpenLoginModal(false));
      logSignInSuccess({
        displayName: `${firstName} ${lastName}`,
        uid: firebase.auth().currentUser?.uid,
      });

      handlePostAuthRedirect();
    } catch (error) {
      throw error; // Re-throw error to be handled in the calling function
    }
  };

  // Helper function to handle authentication errors
  const handleAuthError = (error) => {
    const { email, credential, code: errorCode, message: errorMessage } = error;
    const isIdbError = errorMessage.includes("IDBDatabase");

    const message = isIdbError
      ? ERROR_MESSAGE_IDB_DATABASE
      : errorCode === "auth/account-exists-with-different-credential"
      ? ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST
      : errorMessage;

    setAuthError(message);
    logError({
      message: "GoogleSignIn: Error login user with google sign in",
      error,
      email,
      credential,
      errorCode,
    });
  };

  // Log and Error Utility
  const logError = (errorDetails) => {
    // Implementation for logging errors
    console.error(errorDetails);
  };

  const logInfo = (infoDetails) => {
    // Implementation for logging info
    console.info(infoDetails);
  };

  return (
    <SocialSignInButton
      handleClick={handleClick}
      logoSrc={googleIcon}
      labelText="Sign in with Google"
      alt="google sign in"
      isLoading={isLoading}
      disabled={isNeedDisabled}
    />
  );
};

export default GoogleSignIn;
