import { SET_IS_OPEN_REWARD_MODAL } from "../../../../../redux/actions/auth";
import {
  FETCH_CAT_MENU_LIST_PENDING,
  FETCH_CAT_MENU_LIST_SUCCESS,
  FETCH_CAT_MENU_LIST_ERROR,
  FETCH_CAT_MENU_OPTIONS_PENDING,
  FETCH_CAT_MENU_OPTIONS_SUCCESS,
  FETCH_CAT_MENU_OPTIONS_ERROR,
  SET_CAT_MENU_LIST_ALL,
  SET_ACTIVE_CAT_MENU,
  FETCH_LOYALTY_LIST_PENDING,
  FETCH_LOYALTY_LIST_ERROR,
  FETCH_LOYALTY_LIST_SUCCESS,
  FETCH_REWARD_DETAILS_LIST_PENDING,
  FETCH_REWARD_DETAILS_LIST_SUCCESS,
  FETCH_REWARD_DETAILS_LIST_ERROR,
  FETCH_CUSTOMER_DETAILS_LIST_ERROR,
  FETCH_CUSTOMER_DETAILS_LIST_PENDING,
  FETCH_CUSTOMER_DETAILS_LIST_SUCCESS,
} from "./actions";

export const CatMenuListInitialState = {
  catMenuList: [],
  catMenuListAll: [],
  activeCatMenu: {},
  catMenuOptions: {},
  isFetching: false,
  isFetchingOptions: false,
  error: null,
  errorOptions: null,
};

export const CustomerListInitialState = {
  customerList: [],
  isFetching: false,
  error: null,
};

export const StrategyListInitialState = {
  strategyList: [],
  isFetching: false,
  isFetchingOptions: false,
  error: null,
  errorOptions: null,
};

export const RewardModalInitialState = {
  isOpenRewardModal: false,
};

export const CatMenuListReducer = (state = CatMenuListInitialState, action) => {
  switch (action.type) {
    case FETCH_CAT_MENU_LIST_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_CAT_MENU_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        catMenuList: action.payload,
      };
    case FETCH_CAT_MENU_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    // cat menu options
    case FETCH_CAT_MENU_OPTIONS_PENDING:
      return {
        ...state,
        isFetchingOptions: true,
      };
    case FETCH_CAT_MENU_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        catMenuOptions: action.payload,
      };
    case FETCH_CAT_MENU_OPTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case SET_CAT_MENU_LIST_ALL:
      return {
        ...state,
        catMenuListAll: action.payload,
      };
    case SET_ACTIVE_CAT_MENU:
      return {
        ...state,
        activeCatMenu: action.payload,
      };
    default:
      return state;
  }
};

export const LoyaltyListReducer = (state = CatMenuListInitialState, action) => {
  switch (action.type) {
    case FETCH_LOYALTY_LIST_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_LOYALTY_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        [`isActiveLoyalty_${action.payload.locationId}`]:
          action.payload.loyalty,
      };
    case FETCH_LOYALTY_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const RewardDetailListReducer = (
  state = CatMenuListInitialState,
  action,
) => {
  switch (action.type) {
    case FETCH_REWARD_DETAILS_LIST_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_REWARD_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        [`rewardDetailsList_${action.payload.locationId}`]:
          action.payload.points,
      };
    case FETCH_REWARD_DETAILS_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const CustomerDetailListReducer = (
  state = CustomerListInitialState,
  action,
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_DETAILS_LIST_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_CUSTOMER_DETAILS_LIST_SUCCESS:
      console.log("asdfsfdgfdg4fg45dfgfghfh", action.payload)
      return {
        ...state,
        isFetching: false,
        [`customerDetailsList_${action.payload.locationId}`]:
          action.payload.address,
      };
    case FETCH_CUSTOMER_DETAILS_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const loginModalReducer = (state = RewardModalInitialState, action) => {
  console.log("action", action);
  switch (action.type) {
    case SET_IS_OPEN_REWARD_MODAL:
      return {
        ...state,
        isOpenRewardModal: action.payload,
      };
    default:
      return state;
  }
};
