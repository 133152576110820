import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { logError } from "../../../../../services/logging";
import { useForm } from "react-hook-form";
import ButtonDefault from "../../../../common/buttons/ButtonDefault";
import TextFieldDefault from "../../../../common/textFields/TextFieldDefault";
import { Registration } from "../../../../../utils/Registration";
import firebase from "../../../../../utils/firebase";
import StickyButton from "../../../../common/buttons/StickyButton";
import { RegistrationNew } from "../../../../../utils/RegistrationNew";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperFullWidth": {
      borderRadius: theme.shape.borderRadius,
    },
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: theme?.view?.isFixedList ? "initial" : "100%",
      },
    },
  },
  form: {
    padding: theme.spacing(1, 1, 0, 1),
    display: "flex",
    flexDirection: "column",
    // color: theme.palette.text.primaryTextColor,
  },
  titleSection: {
    padding: theme.spacing(2),
    textAlign: "center",
    // color: theme.palette.text.primaryTextColor,
  },
  makeMiddle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textStyle: {
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));

const ERROR_MESSAGE_IDB_DATABASE =
  "Oops! Something went wrong while trying to sign you in. Please try again in a few seconds. If the issue persists, you can refresh your browser, use a different sign-in method, or continue as a guest. We apologise for the inconvenience.";

const RegistrationPageNew = ({
  handleChangeLoginStatus,
  setAuthError,
  setIsOpenEmailVerificationLoadingModal,
  setCustomerDetails,
  isOpenEmailVerificationLoadingModal,
  customerDetails,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [customer, setCustomer] = useState({
    email: "",
    password: "",
  });
  const history = useHistory();
  const { params } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const { register, errors, trigger } = useForm();
  const { isAuthenticated, isOpenLoginModal } = useSelector(
    (state) => state.auth,
  );

  const isActiveLoyalty = useSelector(
    (state) => state.loyalty[`isActiveLoyalty_${params.locationId}`],
  );
  const classes = useStyles();
  console.log("sssssssssssss3sadfsdfg", isActiveLoyalty)
  useEffect(() => {
    setAuthError("");
  }, [isOpenLoginModal]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "marketing") {
      if (value === "true") {
        setCustomer({ ...customer, [name]: false });
      } else {
        setCustomer({ ...customer, [name]: true });
      }
    } else {
      setCustomer({ ...customer, [name]: value });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const trimValues = useCallback(async () => {
    setCustomer({
      firstName: customer?.firstName?.trim(),
      lastName: customer?.lastName?.trim(),
      confirmPassword: customer.confirmPassword?.trim(),
      email: customer?.email?.trim(),
      password: customer?.password?.trim(),
    });
  }, [customer]);

  const handleContinue = async () => {
    setAuthError("");
    // Trim white spaces from the form values.
    trimValues();

    // Trigger form validation using the useForm trigger function.
    // This will validate all the registered fields and return a boolean indicating if the form is valid.
    const isValid = await trigger();

    // If the form is not valid, return early and do not proceed.
    if (!isValid) return;

    RegistrationNew(
      setIsLoading,
      customer,
      setAuthError,
      dispatch,
      history,
      params,
      location,
      logError,
      setIsOpenEmailVerificationLoadingModal,
      setCustomerDetails,
      isActiveLoyalty,
    );
  };

  const handleChangeEmail = (e) => {
    const email = e.target.value;
    setCustomer((prev) => ({ ...prev, email }));

    // Check if email is valid before Firebase request
    if (email.length > 5 && email.includes("@")) {
      firebase
        .auth()
        .fetchSignInMethodsForEmail(email)
        .then((signInMethods) => {
          setEmailExists(signInMethods.length > 0);
        })
        .catch((error) => {
          console.error("Error checking email: ", error);
        });
    }
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const theme = useTheme();
  return (
    <>
  
        <Grid
          container
          className={classes.makeMiddle}
          style={{ padding: "12px 16px" }}
        >
          <Grid item xs={12}>
            <TextFieldDefault
              id="email"
              name="email"
              label="Email Address"
              value={customer.email}
              variant="filled"
              type="email"
              onChange={handleChangeEmail}
              fullWidth
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 80,
              })}
              helperText={
                emailExists ? (
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="body1"
                      style={{
                        color: "red",
                        paddingRight: "8px",
                        alignItems: "center",
                      }}
                    >
                      This email is already registered.
                    </Typography>
                    <Typography
                      style={{ cursor: "pointer", fontWeight: "bold" }}
                      onClick={handleChangeLoginStatus}
                    >
                      Login
                    </Typography>
                  </div>
                ) : errors.email ? (
                  "Please enter Email Address"
                ) : (
                  ""
                )
              }
              error={errors.email?.type}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldDefault
              id="firstName"
              name="firstName"
              label="First Name"
              value={customer.firstName}
              variant="filled"
              onChange={handleChange}
              fullWidth
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 80,
              })}
              helperText={errors.firstName ? "Please enter First Name" : ""}
              error={errors.firstName?.type}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldDefault
              id="lastName"
              name="lastName"
              label="Last Name"
              value={customer.lastName}
              variant="filled"
              onChange={handleChange}
              fullWidth
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 80,
              })}
              helperText={errors.lastName ? "Please enter Last Name" : ""}
              error={errors.lastName?.type}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldDefault
              id="password"
              name="password"
              label="Password"
              value={customer.password}
              variant="filled"
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              fullWidth
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 80,
              })}
              helperText={errors.password ? "Please enter Password" : ""}
              error={errors.password?.type}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldDefault
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              value={customer.confirmPassword}
              variant="filled"
              onChange={handleChange}
              fullWidth
              type={showConfirmPassword ? "text" : "password"}
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 80,
                validate: (value) =>
                  value === customer.password || "Passwords do not match",
              })}
              helperText={errors.confirmPassword ? "Password mismatch" : ""}
              error={errors.confirmPassword?.type}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleConfirmPasswordVisibility}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <StickyButton
              flex={2}
              style={{
                width: "100%",
                marginTop: "12px",
                marginBottom: "12px",
                height: "40px",
              }}
              onClick={handleContinue}
              disabled={isLoading || isAuthenticated}
            >
              {isLoading ? (
                <CircularProgress size={20} color="primary" />
              ) : (
                "Register"
              )}
            </StickyButton>
          </Grid>
          <Grid item xs={12}>
            <Grid container style={{ display: "flex", alignItems: "center" }}>
              <Grid item xs={3} sm={4}>
                <Divider
                  style={{
                    margin: "4px 0",
                    backgroundColor: theme.palette.primary.contrastText,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography className={classes.textStyle}>
                  or Register with
                </Typography>
              </Grid>
              <Grid item xs={3} sm={4}>
                <Divider
                  style={{
                    margin: "4px 0",
                    backgroundColor: theme.palette.primary.contrastText,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Typography
                style={{
                  fontSize: "18px",
                }}
              >
                Don't have an account?
              </Typography>
              <Button
                onClick={handleChangeLoginStatus}
                style={{ textTransform: "none" }}
                disabled={isAuthenticated}
              >
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    paddingLeft: "8px",
                  }}
                >
                  Login
                </Typography>
              </Button>
            </div>
          </Grid>
        </Grid>
    </>
  );
};

RegistrationPageNew.propTypes = {};
RegistrationPageNew.defaultProps = {};

export default RegistrationPageNew;
