import firebase from "../utils/firebase";
import { logError } from "../services/logging";
import {
  customerUpdateNew,
  getCustomerValidationNew,
} from "../services/customerService";
import { setLocalStore } from "./utils";
import { loginUserSuccess, setIsOpenLoginModal } from "../redux/actions/auth";
import {
  fetchLoyaltyListAsync,
} from "../components/@pages/storeHome/CatMenu/redux/actions";

const ERROR_MESSAGE_IDB_DATABASE =
  "Oops! Something went wrong while trying to sign you in. Please try again in a few seconds. If the issue persists, you can refresh your browser, use a different sign-in method, or continue as a guest. We apologise for the inconvenience.";

// Common function to handle login
export const handleSignInNew = async (
  setIsLoading,
  setIsNeedDisabled,
  customer,
  setIssOpenFirstNameLastNameModal,
  setToken,
  setIsEmptyLastName,
  setGoogleFirstName,
  setAuthError,
  dispatch,
  history,
  params,
  location,
  setIsOpenEmailVerificationLoadingModal,
  setCustomerDetails,
  isActiveLoyalty,
) => {
  try {
    await setAuthPersistence();

    setIsLoading(true);
    setIsNeedDisabled(true);

    const firebaseUser = await signInUser(customer.email, customer.password);

    if (firebaseUser) {
      const idToken = await firebaseUser.getIdToken(true);

      await handlePostLogin(
        firebaseUser,
        idToken,
        setIssOpenFirstNameLastNameModal,
        setToken,
        setIsEmptyLastName,
        setGoogleFirstName,
        setIsLoading,
        dispatch,
        history,
        params,
        location,
        setAuthError,
        setIsNeedDisabled,
        setIsOpenEmailVerificationLoadingModal,
        customer,
        setCustomerDetails,
        isActiveLoyalty,
      );
    }
  } catch (error) {
    console.log("Aaaaaaaasd23asdsafdsf", error, customer);
    handleAuthError(error, setAuthError, setIsLoading, setIsNeedDisabled);
  } finally {
    setIsLoading(false);
    setIsNeedDisabled(false);
  }
};

// Helper function to set Firebase authentication persistence
const setAuthPersistence = async (
  setAuthError,
  setIsLoading,
  setIsNeedDisabled,
) => {
  try {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  } catch (error) {
    handleAuthError(error, setAuthError, setIsLoading, setIsNeedDisabled);
  }
};

// Helper function to sign in user using email and password
const signInUser = async (email, password) => {
  try {
    const result = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    return result.user;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
};

// Post-login handling: validate user and redirect
const handlePostLogin = async (
  firebaseUser,
  token,
  setIssOpenFirstNameLastNameModal,
  setToken,
  setIsEmptyLastName,
  setGoogleFirstName,
  setIsLoading,
  dispatch,
  history,
  params,
  location,
  setAuthError,
  setIsNeedDisabled,
  setIsOpenEmailVerificationLoadingModal,
  customer,
  setCustomerDetails,
  isActiveLoyalty,
) => {
  try {
    await validateCustomer(
      firebaseUser,
      token,
      setIssOpenFirstNameLastNameModal,
      setToken,
      setIsEmptyLastName,
      setGoogleFirstName,
      setIsLoading,
      dispatch,
      history,
      params,
      location,
      setAuthError,
      setIsNeedDisabled,
      setIsOpenEmailVerificationLoadingModal,
      customer,
      setCustomerDetails,
      isActiveLoyalty,
    );
  } catch (error) {
    if (firebaseUser?.displayName) {
      await registerNewCustomer(
        firebaseUser,
        token,
        setIsEmptyLastName,
        setIssOpenFirstNameLastNameModal,
        setGoogleFirstName,
        setToken,
        setIsLoading,
        dispatch,
        history,
        params,
        location,
        setAuthError,
        setIsNeedDisabled,
        setIsOpenEmailVerificationLoadingModal,
        customer,
        setCustomerDetails,
        isActiveLoyalty,
      );
    } else {
      setIssOpenFirstNameLastNameModal(true); // Prompt user for additional info if necessary
      setToken(token);
    }
  }
};

// Helper function to validate customer existence
const validateCustomer = async (
  firebaseUser,
  token,
  setIssOpenFirstNameLastNameModal,
  setToken,
  setIsEmptyLastName,
  setGoogleFirstName,
  setIsLoading,
  dispatch,
  history,
  params,
  location,
  setAuthError,
  setIsNeedDisabled,
  setIsOpenEmailVerificationLoadingModal,
  customer,
  setCustomerDetails,
  isActiveLoyalty,
) => {
  try {
    const response = await getCustomerValidationNew(token); // Assuming this validates the customer in your system
    setLocalStore("customerId", response.data.data.id);
    setLocalStore("shortId", response.data.data.shortId);
    if (response.data.data.firstName && response.data.data.lastName) {
      if (!firebaseUser.displayName) {
        await firebaseUser.updateProfile({
          displayName: `${response.data.data.firstName} ${response.data.data.lastName}`,
        });
      }
      setLocalStore("customerId", response.data.data.id);
      setLocalStore("shortId", response.data.data.shortId);
      const res = response.data.data;
      res.uid = firebaseUser.uid;
      res.email = firebaseUser.email;
      await finalizeLogin(
        res,
        token,
        dispatch,
        history,
        params,
        location,
        setAuthError,
        setIsLoading,
        setIsNeedDisabled,
        setIsOpenEmailVerificationLoadingModal,
        {
          firstName: response.data.data.firstName,
          lastName: response.data.data.lastName,
        },
        setCustomerDetails,
        firebaseUser,
        isActiveLoyalty,
      );
    } else {
      setIssOpenFirstNameLastNameModal(true);
      setToken(token);
    }
  } catch (error) {
    await registerNewCustomer(
      firebaseUser,
      token,
      setIsEmptyLastName,
      setIssOpenFirstNameLastNameModal,
      setGoogleFirstName,
      setToken,
      setIsLoading,
      history,
      params,
      location,
      setAuthError,
      setIsNeedDisabled,
      setIsOpenEmailVerificationLoadingModal,
      customer,
      setCustomerDetails,
      isActiveLoyalty,
    );
  }
};

const promptForLastName = (
  firstName,
  token,
  setIsEmptyLastName,
  setIssOpenFirstNameLastNameModal,
  setGoogleFirstName,
  setToken,
  setIsLoading,
) => {
  setIsEmptyLastName(true);
  setIssOpenFirstNameLastNameModal(true);
  setGoogleFirstName(firstName);
  setToken(token);
  setIsLoading(false);
};

const promptForFirstNameLastName = (
  token,
  setIssOpenFirstNameLastNameModal,
  setToken,
  setIsLoading,
) => {
  setIssOpenFirstNameLastNameModal(true);
  setToken(token);
  setIsLoading(false);
};

// Helper function to register a new customer
const registerNewCustomer = async (
  firebaseUser,
  token,
  setIsEmptyLastName,
  setIssOpenFirstNameLastNameModal,
  setGoogleFirstName,
  setToken,
  setIsLoading,
  dispatch,
  history,
  params,
  location,
  setAuthError,
  setIsNeedDisabled,
  setIsOpenEmailVerificationLoadingModal,
  customer,
  setCustomerDetails,
  isActiveLoyalty,
) => {
  const fullName = firebaseUser.displayName || "";
  const [firstName, ...lastNameParts] = fullName.split(" ");
  const lastName = lastNameParts.join(" ") || "";
  try {
    if (!lastName && !firstName) {
      promptForFirstNameLastName(
        token,
        setIssOpenFirstNameLastNameModal,
        setToken,
        setIsLoading,
      );
    } else if (!lastName) {
      promptForLastName(
        firstName,
        token,
        firstName,
        token,
        setIsEmptyLastName,
        setIssOpenFirstNameLastNameModal,
        setGoogleFirstName,
        setToken,
        setIsLoading,
      );
    } else {
      const response = await customerUpdateNew(token, firstName, lastName);
      setLocalStore("customerId", response.data.data.id);
      setLocalStore("shortId", response.data.data.shortId);
      await firebaseUser.updateProfile({
        displayName: `${firstName} ${lastName}`,
      });
      await finalizeLogin(
        firebaseUser,
        token,
        dispatch,
        history,
        params,
        location,
        setAuthError,
        setIsLoading,
        setIsNeedDisabled,
        setIsOpenEmailVerificationLoadingModal,
        { firstName, lastName },
        setCustomerDetails,
        firebaseUser,
        isActiveLoyalty,
      );
    }
  } catch (error) {}
};

// Helper function to finalize the login process
const finalizeLogin = async (
  firebaseData,
  token,
  dispatch,
  history,
  params,
  location,
  setAuthError,
  setIsLoading,
  setIsNeedDisabled,
  setIsOpenEmailVerificationLoadingModal,
  customer,
  setCustomerDetails,
  firebaseUser,
  isActiveLoyalty,
) => {
  try {
    if (!firebaseUser.emailVerified) {
      await firebaseUser.sendEmailVerification();

      setCustomerDetails({
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: firebaseUser.email,
      });
    }
    console.log("sssssssss445345dfgdfg", isActiveLoyalty?.isActive)
    dispatch(fetchLoyaltyListAsync(params.locationId, true));


    dispatch(
      loginUserSuccess(
        `${firebaseData.firstName} ${firebaseData.lastName}`,
        firebaseData.email,
      ),
    );

    setLocalStore("userId", firebaseUser.uid);
    setLocalStore("photoURL", firebaseUser?.photoURL);
    setLocalStore("idToken", token);

    dispatch(setIsOpenLoginModal(false));
  } catch (error) {
    setAuthError(error.message);
  }
};

// Error handling function
const handleAuthError = (
  error,
  setAuthError,
  setIsLoading,
  setIsNeedDisabled,
) => {
  const errorMessage = error.message.includes("IDBDatabase")
    ? ERROR_MESSAGE_IDB_DATABASE
    : error.message;
  setAuthError(errorMessage);

  logError({
    message: "Firebase SignIn: Error during login",
    error,
    email: error.email,
    credential: error.credential,
    errorCode: error.code,
  });

  setIsLoading(false);
  setIsNeedDisabled(false);
};
