import { createTheme } from "@material-ui/core/styles";

export default function getSecondaryTheme(
  defaultTextColor,
  primaryTextColor,
  actionTextColor,
  titleTextColor,
  subtitleTextColor,
  itemTextColor,
  itemDescColor,
  menuTextColor,
  accentTextColor,
  secondaryTextColor,

  primaryColor,
  secondaryColor,

  menuBackgroundColor,
  categoryBackgroundColor,
  accentColor,

  boarderColor,

  isDark,

  borderRadius,
) {
  return createTheme({
    props: {
      // Name of the component ⚛️
      MuiIconButton: {
        // The default props to change
        padding: 0,
      },
    },
    overrides: {

      // Style sheet name ⚛️
      MuiIconButton: {
        // Name of the rule
        root: {
          padding: 2,
        },
      },
      MuiInputLabel: {
        animated: {
          fontSize: 18,
        },
      },
      MuiToggleButton: {},
      MuiFormControl: {
        root: {
          margin: "4px 0",
        },
      },

      MuiInputBase: {
        input: {
          "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
        },
      },
      PrivateSwitchBase: {
        root: {
          padding: 4,
        },
      },
      MuiFormLabel: {
        root: {
          color: defaultTextColor,
          "&$focused": {
            color: defaultTextColor,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          position: "relative",
          color: defaultTextColor,
          "& $notchedOutline": {
            borderColor: defaultTextColor,
          },
          "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
            borderColor: defaultTextColor,
            "@media (hover: none)": {
              borderColor: defaultTextColor,
            },
          },
          "&$focused $notchedOutline": {
            borderColor: defaultTextColor,
            borderWidth: 1,
          },
        },
      },
      MuiFilledInput: {
        underline: {
          borderRadius,
          borderBottom: "none",
          "&:hover": {
            borderBottom: "none",
          },
          "&:before": {
            borderBottom: "none",
          },
          "&:after": {
            borderBottom: "none",
          },
          "&:hover:before": {
            borderBottom: "none",
          },
        },
      },
    },
    palette: {
      primary: {
        main: primaryColor,
        contrastText: primaryTextColor,
      },
      secondary: {
        main: secondaryColor,
        contrastText: secondaryTextColor,
      },
      background: {
        menuBackgroundColor,
        categoryBackgroundColor,
        boarderColor,
        accentColor,
      },
      text: {
        primary: defaultTextColor,
        actionTextColor,
        titleTextColor,
        subtitleTextColor,
        itemTextColor,
        itemDescColor,
        menuTextColor,
        accentTextColor,
        primaryTextColor,
        secondaryTextColor,
      },
      type: isDark ? "dark" : "light",
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: "Lato",
      // fontFamily: "'PT Sans', sans-serif;",
      fontSize: 15,
      lineHeight: 18,
      letterSpacing: 0,
    },
    shape: {
      borderRadius,
    },
  });
}
