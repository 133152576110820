import React, { useEffect, useState } from "react";

import { Hidden } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import PaymentSection from "../../components/@pages/payments/PaymentMethods/CreditCardPayment/stripe/PaymentSection";
import AlertMessage from "../../components/common/AlertMessage";
import BottomNavButtons from "../../components/common/BottomNavButtons";
import OrderSuccessAlertDialog from "../../components/common/dialogs/OrderSuccessAlertDialog";
import StickyBtnWrapper from "../../components/common/StickyBtnWrapper";
import Stepper from "../../components/Stepper";
import MobileStepper from "../../components/Stepper/MobileStepper";
import ContentContainer from "../../containers/ContentContainer";
import ContentSection from "../../containers/ContentSection";
import OrderInfoSection from "../../containers/OrderInfoSection";
import PageWrapper from "../../containers/PageWrapper";
import SecondaryThemeProvider from "../../containers/SecondaryThemeProvider";
import store from "../../redux";
import {
  logError,
  logInfo,
  transferLogsServerAndClearLocalLogs,
} from "../../services/logging";
import { completeOrder, confirmOrder } from "../../services/orderService";
import FacebookPixel from "../../utils/analytics/FacebookPixel";
import {
  PAYMENT_METHOD_CARD,
  PAYMENT_METHOD_CASH,
} from "../../utils/constants";
import { getOrderData } from "../../utils/order";
import {
  clearLocalStoreOnOrderSuccess,
  getLocalStore,
  setLocalStore,
} from "../../utils/storage/localStorage";
import { getCompleteOrderErrorMessage } from "../../utils/utils";
import OrderDetails from "../../components/@pages/confirm";

/**
 * This component represents the Confirm Page, where the user can review the order details and proceed to payment.
 * It handles both credit card payments and cash payments and shows success modals accordingly.
 */
const ConfirmPage = () => {
  const [orderStatusPageLink, setOrderStatusPageLink] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openCardPaymentSection, setOpenCardPaymentSection] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [timer, setTimer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    entityId: "",
    publicKey: "",
  });
  const history = useHistory();
  const { params } = useRouteMatch();
  const location = useLocation();

  const { storeInfo, serviceCharge, extraCharges } = useSelector(
    (state) => state.store,
  );
  const isActiveLoyalty = useSelector(
    (state) => state.loyalty[`isActiveLoyalty_${params.locationId}`],
  );
  const customerId = getLocalStore("customerId");

  useEffect(
    () => () => {
      clearTimeout(timer);
    },
    [timer],
  );

  const {
    selectedDeliveryMethod,
    selectedDeliveryTimeType,
    selectedDateTime,
    selectedDeliveryData,
    orderDiscounts,
    deliveryLocation,
    totalPriceItems,
    totalDiscount,
    discountAttachedCartItems,
    orderNotes,
    cartItems,
    appliedDiscounts,
  } = useSelector((state) => state.cart);
  const { selectedPaymentMethod } = useSelector((state) => state.payments);
  const {
    customer: { firstName, lastName, phoneNumber, email, marketing },
  } = useSelector((state) => state.contact);
  let emailVerified = getLocalStore("emailVerified");

  const [isEmailVerified, setIsEmailVerified] = useState(false);
  useEffect(() => {
    setIsEmailVerified(emailVerified);
  }, [emailVerified]);
  /**
   * Function to perform cleanup tasks and reset the state after successful order placement.
   */
  const storeCleanup = () => {
    clearLocalStoreOnOrderSuccess();
    store.dispatch({
      type: "RESET",
    });
  };

  /**
   * This function handles the continuation of the order process when the user clicks on the "Continue" button
   * on the confirmation page. It determines the selected payment method, prepares the required data, and makes
   * appropriate API calls for both credit card and cash payments. It also manages loading states, success modals,
   * and error handling during the order placement process. The function updates the local storage with relevant
   * order details, tracks the payment information using Facebook Pixel, and redirects the user to the order status
   * page upon successful completion of the order.
   */
  const handleContinue = async () => {
    // Set isLoading to true to show a loading indicator while processing the order.
    setIsLoading(true);

    // Get the orderId and sessionVersion from the local storage.
    const orderId = getLocalStore("orderId");
    const sessionVersion = getLocalStore("sessionVersion");

    // Set the orderStatusPageLink to navigate to the order status page after order placement.
    setOrderStatusPageLink(
      `/location/${params.locationId}/order-status/${orderId}`,
    );

    // Check if the selected payment method is credit card.
    if (selectedPaymentMethod === PAYMENT_METHOD_CARD) {
      // Prepare the data required for the confirm order API call.
      const data = await getOrderData(
        sessionVersion,
        cartItems,
        discountAttachedCartItems,
        orderDiscounts,
        selectedDeliveryData,
        totalPriceItems,
        selectedDateTime,
        selectedDeliveryTimeType,
        selectedDeliveryMethod,
        firstName,
        lastName,
        phoneNumber,
        email,
        marketing,
        deliveryLocation,
        selectedPaymentMethod,
        totalDiscount,
        orderNotes,
        serviceCharge,
        extraCharges,
        appliedDiscounts,
        storeInfo,
      );

      // Save the payment amount to the local storage for later use.
      setLocalStore("paymentAmount", data.paymentAmount);

      // Log the information about the card payment and the data sent for the confirm order request.
      logInfo({
        message: "Card payment selected. making confirm order request. ",
        // orderData: data,
      });
      try {
        console.log("sdfsdfgfdgf4fgfhfgh 54", isActiveLoyalty);

        if (isActiveLoyalty?.isActive && isEmailVerified) {
          data.loyaltyId = customerId || "";
        } else {
          data.loyaltyId = "";
        }

        // Call the confirm order API to initiate the payment process.
        const res = await confirmOrder(params.locationId, orderId, data);

        // Check if the API call was successful (received 200 OK).
        if (res.status === 200) {
          // Update the orderSessionVersion in the local storage.
          setLocalStore("sessionVersion", res?.data?.data?.orderSessionVersion);
          // Set the openCardPaymentSection state to true to show the payment section.
          setOpenCardPaymentSection(true);
          // Set the paymentData state with the response data from the API.
          setPaymentData(res.data.data);
          // Track the payment information using Facebook Pixel.
          FacebookPixel.addPaymentInfo(cartItems, data.paymentAmount, "CARD");
          // Log the information about the successful confirm order request.
          logInfo({
            message: "confirm order success",
            confirmOrderRes: res?.data,
          });
        } else {
          // Log an error if the confirm order request did not receive 200 OK.
          logError({
            message:
              "CRITICAL | Error confirm order request did not receive 200 OK | make sure order confirmed",
          });
        }
      } catch (error) {
        // Log an error if there was an exception or an error in the confirm order request.
        logError({ message: "CRITICAL | Error confirm order request", error });

        // Check if there is an error message in the response data and display it as an alert.
        if (error?.response?.data?.message) {
          setIsLoading(false);
          return setAlertMessage(error?.response?.data?.message);
        }

        // Set a default alert message for other error cases during confirm order.
        setAlertMessage(
          "Error placing the order! Please restart the order or please contact the restaurant if the issue persists",
        );
      }
    } else {
      // Log the information when card payment is not selected and making a complete order request (cash payment).
      logInfo({
        message: "card payment not selected. making complete order request",
        selectedPaymentMethod,
      });
      try {
        // Call the complete order API to finalize the order for cash payment.
        const res = await completeOrder(
          params.locationId,
          orderId,
          getCompleteOrderErrorMessage(
            storeInfo.businessDisplayName,
            storeInfo.contactNo,
          ),
        );

        // Log the response data received from the complete order request.
        logInfo({
          message: "complete order request response received.",
          // completeOrderRequestRes: res?.data,
        });

        // Check if the API call was successful (received 200 OK).
        if (res.status === 200) {
          // Show the success modal to the user.
          setShowSuccessModal(true);
          // Reset the loading state.
          setIsLoading(false);
          // Get the payment amount from the local storage for tracking with Facebook Pixel.
          const paymentAmount = getLocalStore("paymentAmount");
          // Track the purchase using Facebook Pixel.
          FacebookPixel.purchase(cartItems, paymentAmount);
          // if (storeInfo?.tikTokAnalyticsId) {
          //   TicktockPixel.completePayment(cartItems, paymentAmount);
          // }
          // Set a timeout to hide the success modal after 5 seconds and clean up the store state.
          const timeout = setTimeout(() => {
            setShowSuccessModal(false);
            storeCleanup();

            // Log the successful complete order and redirect to the order status page.
            logInfo({
              message:
                "complete order successful. redirecting to order status page",
            });

            // Transfer logs to the server and clear local logs.
            transferLogsServerAndClearLocalLogs();
            // Redirect to the order status page.
            history.replace(
              `/location/${params.locationId}/order-status/${orderId}`,
            );
          }, 5000);

          // Save the timeout ID in the state for cleanup when the component unmounts.
          setTimer(timeout);
        } else {
          // Log an error if the complete order request did not receive 200 OK.
          logError({
            message:
              "CRITICAL | Error complete order request. did not receive 200 OK",
          });

          // Set an alert message for error cases during complete order.
          setAlertMessage("Error placing the order!");
        }
      } catch (err) {
        // Log an error if there was an exception or an error in the complete order request.
        logError({
          message:
            "CRITICAL | Error complete order request. EXCEPTION OCCURRED",
          error: err,
          errorRes: err?.response,
        });

        // Set a default alert message for other error cases during complete order.
        setAlertMessage(
          err?.response?.data?.message ||
            "Unexpected error. Please check your connection",
        );
      }
    }

    // Set isLoading to false after completing the order processing.
    setIsLoading(false);
  };

  /**
   *   This function is responsible for handling the backward navigation when the user clicks on the "Back" button
   * on the confirmation page. It redirects the user to the payments page with the appropriate location ID and any
   * existing search parameters.
   */
  const handleBackward = () => {
    // Navigate to the payments page
    history.push({
      pathname: `/location/${params.locationId}/payments`,
      search: location.search,
    });
  };

  const isCashPayment = selectedPaymentMethod === PAYMENT_METHOD_CASH;

  return (
    <>
      <AlertMessage
        message={alertMessage}
        setMessage={setAlertMessage}
        severity="error"
        autoHideDuration={60000}
      />
      <PageWrapper>
        <OrderInfoSection />
        <SecondaryThemeProvider>
          <OrderSuccessAlertDialog
            open={showSuccessModal}
            orderStatusPageLink={orderStatusPageLink}
            timer={timer}
          />
          <ContentSection>
            {!openCardPaymentSection && (
              <MobileStepper
                activeStep={4}
                handleNext={handleContinue}
                handleBack={handleBackward}
                isLoading={isLoading}
                labelNext={
                  selectedPaymentMethod === PAYMENT_METHOD_CARD ? "Pay" : "Done"
                }
              />
            )}
            <Stepper activeStep={3} />
            {!openCardPaymentSection && (
              <ContentContainer>
                {!openCardPaymentSection && <OrderDetails />}
              </ContentContainer>
            )}
            {openCardPaymentSection && (
              <PaymentSection
                paymentData={paymentData}
                setOpenCardPaymentSection={setOpenCardPaymentSection}
                openCardPaymentSection={openCardPaymentSection}
                setShowSuccessModal={setShowSuccessModal}
                setTimer={setTimer}
                timer={timer}
              />
            )}

            {!openCardPaymentSection && (
              <div>
                <Hidden xsDown>
                  <BottomNavButtons
                    handleBackward={handleBackward}
                    handleContinue={handleContinue}
                    continueButtonLabel={
                      isCashPayment ? "Confirm order" : "Continue to payments"
                    }
                  />
                </Hidden>

                <Hidden smUp>
                  <StickyBtnWrapper>
                    <BottomNavButtons
                      handleBackward={handleBackward}
                      handleContinue={handleContinue}
                      continueButtonIsLoading={isLoading}
                      continueButtonLabel={
                        isCashPayment ? "Confirm order" : "Continue to payments"
                      }
                    />
                  </StickyBtnWrapper>
                </Hidden>
              </div>
            )}
          </ContentSection>
        </SecondaryThemeProvider>
      </PageWrapper>
    </>
  );
};

ConfirmPage.propTypes = {};
ConfirmPage.defaultProps = {};

export default ConfirmPage;
