import axios from "axios";
import axiosClient from "./client";

// eslint-disable-next-line import/prefer-default-export
export async function getCatMenuItems(locationId) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_MENU}/public/location/${locationId}/published/eat_presto`,
    method: "GET",
  });
}

export async function getCatMenuOptions(locationId) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_MENU}/public/location/${locationId}/published/menu-options/eat_presto`,
    method: "GET",
  });
}

export async function getRewards(locationId) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_LOYALTY}/location/${locationId}/rewards`,
    method: "GET",
  });
}

export async function getRewardsDetails(locationId) {
  const timestamp = Date.now(); // Add a unique timestamp

  return axiosClient({
    
    url: `${process.env.REACT_APP_API_LOYALTY}/location/${locationId}/loyaltyEatpresto?cache_buster=${timestamp}`,
    method: "GET",
  });
}

export async function getStrategyDetails(locationId) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_LOYALTY}/location/${locationId}/getStrategy`,
    method: "GET",
  });
}

export async function getLoyalty(locationId) {
  return axios({
    url: `${process.env.REACT_APP_API_LOYALTY}/location/${locationId}/loyalty`,
    method: "GET",
  });
}
